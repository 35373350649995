<template>
  <section class="related-product my-5">
    <div class="row pl-3 px-md-5">
      <div class="col">
        <h2>You May Also Like</h2>
      </div>
    </div>
    <div class="row">
      <div class="col slider-product">
        <PuSkeleton width="100%" height="230px" v-if="isLoading" />
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box m-0 w-100"
          v-if="products.length"
        >
          <ProductBox
            :product="product"
            v-for="(product, index) in products"
            :key="index"
            class="v-align-top"
          />
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductBox from "@/components/mains/ProductBox.vue";

export default {
  name: "RelatedProduct",
  components: {
    VueSlickCarousel,
    ProductBox,
  },
  props: {
    parentData: Number,
  },
  data() {
    return {
      products: [],
      idCat: this.parentData,
      slickSettings: {
        // centerMode: true,
        // centerPadding: "20px",
        // slidesToShow: 5,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        swipeToSlide: true,
        // lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              // centerMode: false,
              // slidesToShow: 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: false,
              // centerMode: false,
              // slidesToShow: 2,
              rows: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
      isLoading: true,
    };
  },
  watch: {
    "$store.getters.getResellerData"(resellerData) {
      if (resellerData.status_verify) this.getRelatedProducts();
    },
  },
  mounted() {
    this.getRelatedProducts();
  },
  methods: {
    getRelatedProducts() {
      this.isLoading = true;
      const filter = {
        filters: [
          {
            operator: "in",
            property: "category.id",
            values: [{ value: this.idCat.toString() }],
          },
        ],
      };
      this.$api.product.getProductList(0, 10, [{"property":"numOfOrder","direction":"desc"}], filter)
        .then((response) => {
          this.setProducts(response.data);
          this.firebaseViewItemList(this.products);
        })
        .catch((error) => console.log(error));
      this.isLoading = false;
    },
    setProducts(data) {
      this.products = data["data"]["result"];
    },
    firebaseViewItemList(data) {
      let items = [];
      for (let i = 0; i < data.length; i++) {
        items.push({
          item_id: data[i].id.toString(),
          item_name: data[i].name,
          item_brand: data[i].brand_name,
          currency: "IDR",
          price: data[i].price_display,
          quantity: data[i].total_stock,
        });
      }
      // when user visits the product detail and sees a list called "Related Products"
      this.$firebaseLogEvent("view_item_list", {
        currency: "IDR",
        items: items,
      });
    },
  },
};
</script>

